export default (reverseDirection = false) => ({
	async init() {
		const { default: Swiper } = await import("../libs/Swiper")

		new Swiper(this.$el, {
			slidesPerView: 2,
			spaceBetween: 10,
			speed: 6000,
			loop: true,
			autoplay: {
				delay: 1,
				reverseDirection,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			},
			breakpoints: {
				1280: {
					slidesPerView: 5,
					spaceBetween: 24,
				},
				992: {
					slidesPerView: 5,
				},
				768: {
					slidesPerView: 4,
				},
				576: {
					slidesPerView: 3,
				},
			},
		})
	},
})
