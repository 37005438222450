export default () => ({
	async init() {
		const { default: Swiper } = await import("../libs/Swiper")

		matchMedia("(min-width: 768px)").matches &&
			new Swiper(this.$refs.swiper, {
				speed: 500,
				slidesPerView: 3,
				spaceBetween: 10,
				navigation: {
					prevEl: this.$refs.prevArrow,
					nextEl: this.$refs.nextArrow,
				},
				breakpoints: {
					1280: {
						spaceBetween: 30,
					},
				},
			})

		window.addEventListener("resize", () => this.swapDecorImg())
		this.swapDecorImg()
	},
	swapDecorImg() {
		const isMobile = matchMedia("(max-width: 767px)").matches
		if (isMobile) {
			this.$refs.swiperWrapper.append(this.$refs.decorImg)
		} else {
			this.$refs.contentWrap.prepend(this.$refs.decorImg)
		}
	},
})
