export default () => ({
	async init() {
		const { default: Swiper } = await import("../libs/Swiper")

		new Swiper(this.$refs.swiper, {
			speed: 500,
			centeredSlides: true,
			navigation: {
				prevEl: this.$refs.prevArrow,
				nextEl: this.$refs.nextArrow,
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
				},
			},
		})
	},
})
