export default () => ({
	async init() {
		if (!matchMedia("(max-width:1279px)").matches) return

		const { default: Swiper } = await import("../libs/Swiper")

		new Swiper(this.$root, {
			speed: 500,
			navigation: {
				nextEl: this.$refs.nextArrow,
				prevEl: this.$refs.prevArrow,
			},
			breakpoints: {
				768: {
					slidesPerView: 3,
				},
			},
		})
	},
})
