export default () => ({
	async init() {
		const { default: Swiper } = await import("../libs/Swiper")

		new Swiper(this.$refs.swiper, {
			speed: 900,
			navigation: {
				nextEl: this.$refs.nextArrow,
				prevEl: this.$refs.prevArrow,
			},
			pagination: {
				el: this.$refs.pagination,
				clickable: true,
			},
		})
	},
})
