export default (namesCount = 1) => ({
	currentActiveName: -1,
	namesCount: namesCount,
	init() {},
	start() {
		this.currentActiveName = 0
		const interval = setInterval(() => {
			if (this.currentActiveName == namesCount - 1) {
				clearInterval(interval)
			} else this.currentActiveName++
		}, 1200)
	},
})
