export default () => ({
	async init() {
		const { default: Swiper } = await import("../libs/Swiper")

		new Swiper(this.$refs.swiper, {
			speed: 600,
			navigation: {
				prevEl: this.$refs.prevArrow,
				nextEl: this.$refs.nextArrow,
			},
		})
	},
})
