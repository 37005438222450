export default () => ({
	init() {
		if (!this.$refs.textWrap) return

		this.swap()
		window.addEventListener("resize", () => this.swap())
	},
	swap() {
		const isMobile = matchMedia("(max-width: 1279px)").matches

		if (isMobile) {
			this.$refs.bottomContainer.append(this.$refs.textWrap)
		} else {
			this.$refs.topContainer.append(this.$refs.textWrap)
		}
	},
})
