export default () => ({
	shown: false,
	grid: false,
	init() {
		document.readyState == "complete" ? this.createGrid() : window.addEventListener("load", () => this.createGrid())
	},
	async createGrid() {
		const { default: Muuri } = await import("muuri")
		this.grid = new Muuri(this.$refs.documentsWrap, {})
	},
	showMore() {
		if (this.shown) return
		this.shown = true
		const items = this.grid.getItems().filter((item) => !item.isActive())

		this.grid.show(items, {
			instant: true,
		})
	},
})
